import { MenuEntry } from '@encodix/bulluikit'

const config: MenuEntry[] = [
  {
    label: 'WebSite',
    icon: 'HomeIcon',
    href: 'https://www.bullshitinu.com/home',
  },
  // {
  //   label: 'Trade',
  //   icon: 'TradeIcon',
  //   initialOpenState: true,
  //   items: [
  //     {
  //       label: 'Exchange',
  //       href: '/swap'
  //     },
  //     // {
  //     //   label: 'Liquidity',
  //     //   href: '/pool'
  //     // }
  //   ]
  // },
  {
    label: 'Swap',
    icon: 'TradeIcon',
    href: '/swap',
  },
  // {
  //   label: 'Farms',
  //   icon: 'FarmIcon',
  //   href: 'http://mvrsswap.org/#/farms'
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: 'http://mvrsswap.org/#/pools'
  // },
  // {
  //   label: 'Leveraged farming',
  //   icon: 'PoolIcon',
  //   href: 'http://mvrsswap.org/comingsoon',
  // },
  // {
  //   label: 'LaunchPAD',
  //   icon: 'PoolIcon',
  //   href: 'http://mvrsswap.org/#/comingsoon',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'http://mvrsswap.org/#/comingsoon'
  // },
  // {
  //   label: 'Referral',
  //   icon: 'GroupsIcon',
  //   href: 'http://mvrsswap.org/#/referral',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   href: 'http://info.mvrsswap.org',
  // },
  // {
  //   label: 'Price Charts',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'DexGuru',
  //       href: 'https://dex.guru/',
  //     },
  //     {
  //       label: 'PooCoin',
  //       href: 'https://poocoin.app/',
  //     },
  //     {
  //       label: 'BoggedFinance',
  //       href: 'https://charts.bogged.finance/',
  //     },
  //     {
  //       label: 'DexTools',
  //       href: 'https://www.dextools.io/',
  //     },
  //   ],
  // },
  {
    label: 'Audit Report',
    icon: 'AuditIcon',
    items: [
      {
        label: 'Tech-Audit',
        href: 'https://github.com/Tech-Audit/Smart-Contract-Audits/blob/main/TECHAUDIT_BULLSHIT%20INU.pdf',
      },
      // {
      //   label: 'DappRadar',
      //   href: 'https://dappradar.com/',
      // },
      // {
      //   label: 'CoinGecko',
      //   href: 'https://www.coingecko.com/en/',
      // },
      // {
      //   label: 'LiveCoinWatch',
      //   href: 'https://www.livecoinwatch.com/',
      // },
      // {
      //   label: 'Vfat',
      //   href: 'https://vfat.tools/',
      // },
    ],
  },
  {
    label: 'Listings',
    icon: 'LayerIcon',
    items: [
      {
        label: 'BscScan',
        href: 'https://bscscan.com/address/0x99Cd7207d1cbe59033a01892C06889adAe47b0e5',
      },
      {
        label: 'PancakeSwap',
        href: 'https://pancakeswap.finance/swap?outputCurrency=0x99Cd7207d1cbe59033a01892C06889adAe47b0e5',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com',
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com',
      },
      {
        label: 'DEXTools',
        href: 'https://www.dextools.io',
      },
      // {
      //   label: 'Nomics',
      //   href: 'https://nomics.com/assets/mvrs-meta-mvrs',
      // },
      // {
      //   label: 'Live Coin Watch',
      //   href: 'https://www.livecoinwatch.com/price/MetaMVRS-MVRS',
      // },
      // {
      //   label: 'DappRadar',
      //   href: 'https://dappradar.com/',
      // },
      // {
      //   label: 'CoinGecko',
      //   href: 'https://www.coingecko.com/en/',
      // },
      // {
      //   label: 'LiveCoinWatch',
      //   href: 'https://www.livecoinwatch.com/',
      // },
      // {
      //   label: 'Vfat',
      //   href: 'https://vfat.tools/',
      // },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      // {
      //   label: 'YouTube',
      //   href: 'https://www.youtube.com/channel/UCfvqjBHNSLlLtqR_ZY6I95A',
      // },
      {
        label: 'Reddit',
        href: 'https://www.reddit.com/user/BullShitInu',
      },
      // {
      //   label: 'BitCoinTalk',
      //   href: 'https://bitcointalk.org/index.php',
      // },
      {
        label: 'Medium',
        href: 'https://medium.com/@bullshitinu',
      },
      {
        label: 'GitHub',
        href: 'https://github.com/Bullshitinu',
      },
      {
        label: 'Telegram',
        href: 'https://t.me/BullshitInuChat',
      },
      // {
      //   label: 'Facebook',
      //   href: 'https://www.facebook.com/MVRStoken/',
      // },
      {
        label: 'Instagram',
        href: 'https://www.instagram.com/bullshitinu/',
      },
      {
        label: 'Twitter',
        href: 'https://twitter.com/BullShitInu',
      },
      // {
      //   label: 'Voting',
      //   href: 'http://mvrsswap.org/comingsoon',
      // },
    ],
  },
  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.mvrsswap.org/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
